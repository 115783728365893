import { CdkAccordionItem } from '@angular/cdk/accordion';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Maybe,
  TixTicketConfig,
  TixVenueTicket,
  TixVenueTicketConfig
} from '@tix/data-access';
import { expandCollapse } from '@tix/shared/animations';
@Component({
  selector: 'tix-ticket-configuration-expand',
  templateUrl: './ticket-configuration-expand.component.html',
  styleUrls: ['./ticket-configuration-expand.component.scss'],
  animations: [expandCollapse]
})
export class TicketConfigurationExpandComponent implements OnInit {
  @ViewChild('accordion') cdkAccordionItem: CdkAccordionItem;
  @ViewChild('scrollRef') scrollRef: ElementRef<any>;

  @Input() expanded = true;
  @Input() content: TicketConfigurationExpandContent;
  @Input() insideError = false;
  @Input() editable = false;
  @Input() venueTicketConfigs: TicketConfigurationExpandContent[] | undefined;
  @Input() mainSectionFormControl?: FormControl = new FormControl('', [
    Validators.required
  ]);
  @Input() isChild = false;
  @Input()
  deletable = true;
  @Input()
  inline = false;
  @Input() parentTicketId: any;
  @Input() parentTicketIdsArray: string[];

  @Output() editClick = new EventEmitter();
  @Output() deleteClick = new EventEmitter();
  @Output() expandedChange = new EventEmitter<boolean>();

  editMode = false;
  ngOnInit(): void {
    this.expanded = this.isChild ? false : this.expanded;
  }

  expandChevronClick() {
    this.cdkAccordionItem.toggle();
    this.expanded = this.cdkAccordionItem.expanded;
    this.expandedChange.emit(this.expanded);
    setTimeout(() => {
      this.scrollRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }, 100);
  }

  onEditClick() {
    this.editClick.emit();
    this.editMode = !this.editMode;
  }

  get configTicketType() {
    const sectionConfiguration = this.content.originalValue
      ?.sectionConfiguration as Maybe<TixTicketConfig>;
    return sectionConfiguration?.totalTicketsByType;
  }

  get notAllowedToDelete(): boolean {
    return (
      (this.configTicketType && this.configTicketType.ticketsSold > 0) ||
      this.parentTicketIdsArray?.includes(this.content.id)
    );
  }
}

export type TicketConfigurationExpandContent = {
  id: string;
  /**
   * it stores the original ticketConfiguration / sectionConfiguration in order to facilitate conversion between types
   */
  originalValue?: {
    ticketConfiguration?: TixVenueTicket;
    sectionConfiguration?: TixVenueTicketConfig;
  };
  /**
   * Keeps track of this expandContent in order to backtrack easier eventually
   */
  parent?: TicketConfigurationExpandContent;
  mainSection: {
    label: string;
    value: string;
    quantity?: Maybe<number>;
    formControl?: FormControl;
  };
  extraSection: {
    label: string;
    value: string;
  };
  secondarySection: {
    label: string;
    value: string;
  };
  children?: TicketConfigurationExpandContent[];
  formGroup?: FormGroup;
};
