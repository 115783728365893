<div
  cdkAccordionItem
  #accordion="cdkAccordionItem"
  [expanded]="expanded"
  [class.inside-error]="insideError"
  class="mt-4 tix-tc-card"
>
  <div class="tix-tc-content" #scrollRef>
    <div class="tix-tc-info-row">
      <div *ngIf="content.extraSection">
        <p class="tix-tc-info-label">{{ content.extraSection.label }}</p>
        <p class="tix-tc-info-value">{{ content.extraSection.value }}</p>
      </div>
      <div
        class="tix-tc-badge"
        *ngIf="
          content.mainSection.quantity !== undefined &&
          content.mainSection.quantity !== null
        "
      >
        <tix-badge *ngIf="!inline" roundness="full">{{
          content.mainSection.quantity
        }}</tix-badge>
      </div>
      <div class="w-48 lg:max-w-fit">
        <p
          [class.error]="content.mainSection.formControl?.invalid"
          class="tix-tc-info-label"
        >
          {{ content.mainSection.label }}
        </p>
        <p
          [class.error]="content.mainSection.formControl?.invalid"
          class="tix-tc-info-value"
        >
          <tix-inline-edit *ngIf="!isChild; else label">
            <ng-template tixViewMode>
              <div>
                {{
                  editable
                    ? mainSectionFormControl?.value || 'empty'
                    : content.mainSection.value
                }}
              </div>
            </ng-template>
            <ng-template tixEditMode>
              <mat-form-field>
                <input
                  type="text"
                  matInput
                  [formControl]="mainSectionFormControl!"
                />
                <mat-error
                  >{{ content.mainSection.label }} is required</mat-error
                >
              </mat-form-field>
            </ng-template>
          </tix-inline-edit>
          <ng-template #label>
            <div>
              {{
                editable
                  ? mainSectionFormControl?.value || 'empty'
                  : content.mainSection.value
              }}
            </div>
          </ng-template>
        </p>
      </div>

      <div class="tix-tc-middle-info">
        <p class="tix-tc-info-label">{{ content.secondarySection.label }}</p>
        <p class="tix-tc-info-value text-center">
          {{ content.secondarySection.value }}
        </p>
      </div>
      <div class="tix-tc-desktop-actions">
        <ng-template [ngTemplateOutlet]="actions"></ng-template>
      </div>
    </div>
    <div class="tix-tc-mobile-actions">
      <ng-template [ngTemplateOutlet]="actions"></ng-template>
    </div>
  </div>
  <div
    [@expandCollapse]="!accordion.expanded"
    [ngClass]="{
      'overflow-hidden': true,
      'tix-tc-wrapped-content pt-8': accordion.expanded
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
<ng-template #actions>
  <button
    *ngIf="deletable"
    (click)="deleteClick.emit()"
    tix-button
    color="light"
    class="tix-tc-actions-icon"
    [disabled]="notAllowedToDelete"
    [ngClass]="{
      'cursor-not-allowed': notAllowedToDelete
    }"
  >
    <tix-icon svgIcon="trash"></tix-icon>
  </button>
  <div class="tix-tc-chevron-container">
    <tix-expand-chevron
      (click)="expandChevronClick()"
      class="block translate-y-2"
      [expanded]="!accordion.expanded"
    >
    </tix-expand-chevron>
  </div>
</ng-template>
