<ng-template [ngIf]="isLoggedIn | async" [ngIfElse]="loggedOut">
  <button
    tix-flat-button
    [matMenuTriggerFor]="profileMenu"
    color="light"
    class="!ring-0 sm:px-4 px-2"
  >
    <div class="text-white">
      <img
        class="profile-menu-profile-image"
        [src]="
          (userState$ | async)?.profilePicture || 'assets/images/avatar.svg'
        "
        alt="Profile avatar"
      />
      <span class="profile-menu-username">{{
        (userState$ | async)?.displayName
      }}</span>
    </div>
    <tix-icon class="profile-menu-arrow" svgIcon="chevron-down"></tix-icon>
  </button>
  <mat-menu #profileMenu="matMenu" class="w-56">
    <mat-option [routerLink]="['/my-profile', 1]">Account Info</mat-option>
    <mat-option [routerLink]="['/my-profile', 2]">Phone Numbers</mat-option>
    <mat-option [routerLink]="['/my-profile', 3]">Address</mat-option>
    <mat-option [routerLink]="['/company']">Go to my companies</mat-option>
  </mat-menu>
  <button tix-flat-button class="sm:px-4 px-2" color="light" (click)="logout()">
    Logout
  </button>
</ng-template>

<ng-template #loggedOut>
  <button tix-flat-button color="light" (click)="login()">Login</button>
</ng-template>
