<tix-card *ngIf="!inline">
  <tix-card-header *ngIf="showHeader">
    <tix-card-title>Ticket Configurations</tix-card-title>
  </tix-card-header>
  <tix-card-content>
    <ng-container *ngIf="venueTickets.length; else noConfig">
      <cdk-accordion>
        <tix-ticket-configuration-expand
          *ngFor="let venueTicket of venueTickets; let i = index"
          [insideError]="highlightSection(venueTicket)"
          [content]="venueTicket"
          [editable]="true"
          [mainSectionFormControl]="venueTicket.mainSection.formControl"
          (deleteClick)="deleteTicketConfiguration(venueTicket, i)"
        >
          <cdk-accordion class="flex flex-col">
            <tix-ticket-configuration-expand
              *ngFor="
                let venueTicketConfig of venueTicket.children;
                let sectionIndex = index
              "
              [insideError]="highlightSection(venueTicketConfig)"
              [content]="venueTicketConfig"
              [venueTicketConfigs]="venueTicket.children"
              [parentTicketId]="venueTicketConfig.formGroup?.['value'].parentTicketId"
              (deleteClick)="
                deleteSectionConfiguration(
                  venueTicket,
                  venueTicketConfig,
                  sectionIndex
                )
              "
              [isChild]="true"
            >
              <tix-ticket-configuration-section-config
                *ngIf="venueTicketConfig.formGroup as formGroup"
                [disablePools]="disablePools"
                [parentContent]="venueTicket"
                [content]="venueTicketConfig"
                [ticketConfigs]="venueTicket.children || []"
                [ticketConfig]="
                  venueTicketConfig.originalValue?.sectionConfiguration
                "
                [venueId]="venueId"
                [companyId]="companyId"
                [formGroup]="formGroup"
                [ticketTypeBusinessCodeGroup]="
                  ticketTypeBusinessCodeGroup | async
                "
              >
              </tix-ticket-configuration-section-config>
            </tix-ticket-configuration-expand>
            <div class="section-footer">
              <button
                tix-flat-button
                (click)="createNewSectionConfiguration(venueTicket)"
                title="add new section"
                class="m-2 tix-primary tix-flat-button tix-button-base tix-rounded-md slef-end"
              >
                Add another section
              </button>
            </div>
          </cdk-accordion>
        </tix-ticket-configuration-expand>
      </cdk-accordion>
    </ng-container>
    <ng-template #noConfig>
      <p class="card-no-content">No configurations available</p>
    </ng-template>
  </tix-card-content>
  <tix-card-actions align="end" [ngClass]="{ '!py-0 !px-0': !showHeader }">
    <div class="flex items-center">
      <button
        (click)="createNewConfiguration()"
        tix-button
        rounding="full"
        title="add new configuration"
        class="tix-tc-add-button"
      >
        <tix-icon svgIcon="plus-solid"></tix-icon>
      </button>
      <button
        *ngIf="!hideSaveBtn"
        (click)="onSaveClick()"
        [disabled]="disableSaveButton"
        tix-button
        class="!ml-4"
      >
        Save
        <span
          class="ml-1"
          *tixBtnLoading="getSelectedLoadingTicketConfig$ | async"
        ></span>
      </button>
    </div>
  </tix-card-actions>
</tix-card>

<cdk-accordion *ngIf="inline">
  <tix-ticket-configuration-expand
    *ngFor="let venueTicket of venueTickets; let i = index"
    [insideError]="highlightSection(venueTicket)"
    [content]="venueTicket"
    [editable]="true"
    [mainSectionFormControl]="venueTicket.mainSection.formControl"
    [deletable]="false"
    [inline]="inline"
  >
    <cdk-accordion class="flex flex-col">
      <tix-ticket-configuration-expand
        *ngFor="
          let venueTicketConfig of venueTicket.children;
          let sectionIndex = index
        "
        [insideError]="highlightSection(venueTicketConfig)"
        [venueTicketConfigs]="venueTicket.children"
        [content]="venueTicketConfig"
        [parentTicketId]="venueTicketConfig.formGroup?.['value'].parentTicketId"
        [parentTicketIdsArray]="parentTicketIdsArray"
        (deleteClick)="
          deleteSectionConfiguration(
            venueTicket,
            venueTicketConfig,
            sectionIndex
          )
        "
        [isChild]="true"
      >
        <tix-ticket-configuration-section-config
          *ngIf="venueTicketConfig.formGroup as formGroup"
          [disablePools]="disablePools"
          [parentContent]="venueTicket"
          [content]="venueTicketConfig"
          [ticketConfigs]="venueTicket.children || []"
          [ticketConfig]="venueTicketConfig.originalValue?.sectionConfiguration"
          [formGroup]="formGroup"
          [venueId]="venueId"
          [companyId]="companyId"
          [ticketTypeBusinessCodeGroup]="ticketTypeBusinessCodeGroup | async"
        >
        </tix-ticket-configuration-section-config>
      </tix-ticket-configuration-expand>
      <div class="section-footer">
        <button
          tix-flat-button
          (click)="createNewSectionConfiguration(venueTicket)"
          title="add new section"
          class="m-2 tix-primary tix-flat-button tix-button-base tix-rounded-md slef-end"
        >
          Add another section
        </button>
      </div>
    </cdk-accordion>
  </tix-ticket-configuration-expand>
</cdk-accordion>

<!-- <p *ngIf="containsNewSection" class="my-3">
  You will need to save to be able to use the newly created sections as ticket
  pools.
</p> -->

<tix-card-actions
  *ngIf="inline && !hideSaveBtn"
  align="end"
  [ngClass]="{ '!py-0 !px-0': !showHeader }"
>
  <button
    (click)="onSaveClick()"
    [disabled]="disableSaveButton"
    tix-button
    class="!ml-4"
  >
    Save
    <span
      class="ml-1"
      *tixBtnLoading="getSelectedLoadingTicketConfig$ | async"
    ></span>
  </button>
</tix-card-actions>
