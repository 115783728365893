import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  Maybe,
  TixCompanyContact,
  TixContact,
  TixGetRolesQuery,
  TixRole,
  TixUser,
  TixUserRole
} from '@tix/data-access';
import { ContactProfileViewModel } from '@tix/shared/models';
import { TextMaskConfig } from 'angular2-text-mask';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  take
} from 'rxjs/operators';
import { ContactService } from '../services/contact.service';
import {
  TixFullStaffMember,
  TixStaffPartialState,
  TixStaffService
} from '@tix/staff/state';
import {
  TixCheckboxChange,
  getTixIconFailedToSanitizeLiteralError
} from '@tix/shared/ui/components';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSelector } from '@tix/auth/state';
import { TixConfirmDeleteDialogService } from 'libs/shared/ui/components/src/lib/dialog/confirm-delete-dialog/confirm-delete-dialog.service';
@Component({
  selector: 'tix-contact-profile',
  templateUrl: './contact-profile.component.html',
  styleUrls: ['./contact-profile.component.scss']
})
export class TixContactProfileComponent
  implements OnInit, OnDestroy, OnChanges
{
  userId: string;
  isSaving$ = this.staffService.isSavingStaffInfo$;
  isDeleting$ = this.staffService.isDeletingStaffInfo$;
  userRolesList$ = this.staffService.getRoles();
  emailAddress: string;
  @Input() isloading?: boolean | undefined | null;
  @Input() set profileViewModel(user: ContactProfileViewModel | null) {
    if (user) {
      this.currentStaffId = user.contactId;
      this.userId = user.userId;
    }

    this.profileForm.patchValue({
      contactFirstName: user?.firstName,
      contactLastName: user?.lastName,
      contactEmailAddress: user?.emailAddress,
      contactProfilePicture: user?.profilePicture
    });
  }

  emailList: Maybe<TixContact[]>;

  currentStaffId: string;
  companyId: string;

  @Input() isEditMode = false;
  @Input() userAssignedRoles: NonNullable<
    TixFullStaffMember['user']
  >['userRoles'];
  emailList$: Observable<Maybe<TixContact[]>>;

  selectedEmail: string;
  staffMembers$ = this.route.params.pipe(
    switchMap(params =>
      this.staffService.getStaffListForCompany(params['companyId'])
    )
  );

  profileForm: FormGroup = new FormGroup({
    contactFirstName: new FormControl('', [Validators.required]),
    contactLastName: new FormControl('', [Validators.required]),
    contactEmailAddress: new FormControl(
      '',
      [Validators.email],
      [this.createValidator()]
    ),
    contactProfilePicture: new FormControl(''),
    permissions: new FormControl([])
  });

  emailChangeSubject = new Subject<any>();
  userRole: string;
  selectedExistingUser$: Observable<Maybe<TixContact>>;
  selectedExistingUser: Maybe<TixContact>;

  get firstName(): FormControl {
    return this.profileForm.get('contactFirstName') as FormControl;
  }
  get lastName(): FormControl {
    return this.profileForm.get('contactLastName') as FormControl;
  }
  get userEmail(): FormControl {
    return this.profileForm.get('contactEmailAddress') as FormControl;
  }
  get profilePicture(): FormControl {
    return this.profileForm.get('contactProfilePicture') as FormControl;
  }

  get pristineOrInvalid(): boolean {
    return this.profileForm.pristine || this.profileForm.invalid;
  }

  selectedRoles = new Set<string>();

  readonly PHONE_NUMBER_INPUT_MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/
  ];

  readonly PHONE_NUMBER_INPUT_MASK_CONFIG: TextMaskConfig = {
    mask: this.PHONE_NUMBER_INPUT_MASK,
    guide: false
  };

  subscription: Subscription;

  public userId$ = this.store.select(UserSelector.getAuthenticatedUser).pipe(
    map(u => u?.uid),
    take(1)
  );
  public isAdmin$ = this.store.select(UserSelector.getUserRolesInfo).pipe(
    map(u =>
      u?.userRoles.some(
        userRole => (userRole?.role as TixRole).role === 'co_admin'
      )
    ),
    take(1)
  );

  constructor(
    private readonly store: Store<TixStaffPartialState>,
    private confirmDeleteDialogService: TixConfirmDeleteDialogService,
    private readonly contactService: ContactService,
    private route: ActivatedRoute,
    private router: Router,
    private staffService: TixStaffService
  ) {}

  private navigateToStaffPage(staffId: string) {
    this.router.navigate(['company', this.companyId, 'staff', staffId]);
  }

  isEventAdmin: boolean;
  isEventAdminSelected: boolean = false;
  ngOnInit(): void {
    this.emailList$ =
      (this.profileForm.get('contactEmailAddress')?.valueChanges.pipe(
        debounceTime(200),
        switchMap((email: string) => {
          if (email) {
            return this.contactService.getEmailList(email);
          }
          return of([]);
        }),
        mergeMap(users =>
          this.staffMembers$.pipe(
            map(staffMembers =>
              users.filter(
                user =>
                  !staffMembers.some(
                    member =>
                      member.contact?.emailAddress.toLowerCase() ===
                      user.emailAddress.toLowerCase()
                  )
              )
            )
          )
        )
      ) as Observable<Maybe<TixContact[]>>) || of([]);

    this.selectedExistingUser$ =
      this.profileForm.get('contactEmailAddress')?.valueChanges.pipe(
        mergeMap((email: string) => {
          if (!email) return of(this.selectedExistingUser);
          return this.emailList$.pipe(
            map(users => {
              if (email === undefined) {
                return this.selectedExistingUser;
              }
              return users?.find(
                user => user.emailAddress.toLowerCase() === email?.toLowerCase()
              );
            })
          );
        })
      ) || of(undefined);

    this.subscription = this.selectedExistingUser$.subscribe(val => {
      this.selectedExistingUser = this.selectedExistingUser || val;
    });

    this.subscription.add(
      this.route.params.subscribe(params => {
        this.companyId = params['companyId'];
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userAssignedRoles && changes.userAssignedRoles.currentValue) {
      this.userRole = this.userAssignedRoles?.[0]?.role?.role as string;
      this.isEventAdmin = this.userRole === 'event_admin';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUserInfoByEmail(user: TixContact): void {
    this.selectedExistingUser = user;
    this.profileForm.patchValue({
      contactFirstName: user.firstName,
      contactLastName: user.lastName,
      contactEmailAddress: user.emailAddress,
      contactProfilePicture: user.profilePicture,
      permissions: []
    });
    this.emailAddress = user.emailAddress;
    this.profileForm.controls['contactEmailAddress'].setValue(
      user.emailAddress
    );
    // this.store.dispatch(StaffAction.selectStaff({ staffId: user.contactId }));
    // this.profileForm.markAsDirty(); // After dispatching above action user-info will be set but to enable button form should be dirty
  }

  async handleDelete() {
    if (this.currentStaffId && this.companyId) {
      const staffEmail =
        this.profileForm.get('contactEmailAddress')?.value || '';
      const result = await this.confirmDeleteDialogService.confirmDelete({
        confirmationText: `${staffEmail ?? ''}`
      });
      if (result) {
        this.staffService
          .removeExistingUserAsStaff(
            this.companyId,
            this.currentStaffId,
            this.userId,
            this.isEventAdmin
          )
          .subscribe(
            () => {
              this.router.navigate(['/company', this.companyId, 'staff']);
            },
            error => {
              console.log(error);
            }
          );
      }
    }
  }

  get isPermissionSelected() {
    return this.profileForm.value.permissions.length > 0;
  }

  get isSaveButtonDisabled() {
    if (!this.isEditMode)
      return !this.pristineOrInvalid && this.isPermissionSelected;
    return !this.pristineOrInvalid;
  }

  hasError(control: string, error: string): boolean {
    return this.profileForm.get(control)?.hasError(error) ?? false;
  }

  async onSave() {
    const userId = await this.userId$.toPromise();
    if (this.isEditMode) {
      this.subscription.add(
        this.staffService
          .saveStaffMemberInfo({
            contactId: this.currentStaffId,
            firstName: this.profileForm.value.contactFirstName,
            lastName: this.profileForm.value.contactLastName,
            profilePicture: this.profileForm.value.contactProfilePicture,
            updatedAt: 'now()',
            updatedBy: userId
          })
          .subscribe()
      );
    } else if (
      this.selectedExistingUser &&
      this.selectedExistingUser.user?.userId
    ) {
      this.subscription.add(
        this.staffService
          .insertExistingUserAsStaffMember(
            this.companyId,
            this.selectedExistingUser.contactId,
            this.profileForm.value.permissions
          )
          .subscribe(() => {
            this.navigateToStaffPage(this.selectedExistingUser?.contactId);
          })
      );
    } else {
      this.subscription.add(
        this.staffService
          .insertNewUserToCompanyStaff(this.companyId, {
            ...this.profileForm.value,
            contactEmailAddress:
              this.emailAddress ||
              this.profileForm.controls['contactEmailAddress'].value,
            roles: this.profileForm.value.permissions,
            permissions: undefined
          })
          .subscribe(contact => {
            this.navigateToStaffPage(contact.contactId);
          })
      );
    }
    this.profileForm.markAsPristine();
  }

  createValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.staffMembers$.pipe(
        map(staffMembers => {
          if (this.isEditMode) return null;
          const alreadyInStaff = staffMembers.some(
            member =>
              member.contact?.emailAddress.toLowerCase() ===
              control.value.toLowerCase()
          );

          if (alreadyInStaff)
            return { userAlreadyExists: true } as ValidationErrors;

          return null;
        })
      );
    };
  }

  onChange(
    $event: TixCheckboxChange,
    item: Maybe<TixGetRolesQuery['Role'][0]>
  ) {
    let permissions = this.profileForm.value.permissions as string[];

    if ($event.checked) {
      if (item?.role === 'event_admin') {
        permissions = [item.roleId];
        this.isEventAdminSelected = true;
      } else {
        permissions.push(item?.roleId);
      }
    } else {
      permissions = permissions.filter(
        listRoleId => listRoleId != item?.roleId
      );

      if (item?.role === 'event_admin') {
        this.isEventAdminSelected = false;
      }
    }

    this.profileForm.patchValue({
      permissions
    });
  }

  haveOneRole(): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (this.isEditMode || (control.value && control.value.length > 0)) {
        return null;
      }
      return { error: true };
    };
  }
}
