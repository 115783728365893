import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import {
  TixDecrementDiscountLimitGQL,
  TixGetVenueByPrimaryKeyGQL,
  TixInsertPaymentReservationTicketGQL,
  TixPayment,
  TixPaymentInsertInput,
  TixSendETicketGQL,
  TixSendTicketPurchaseEmailGQL,
  TixUpdatePaymentIntentMetadataGQL,
  TixVenue
} from '@tix/data-access';
import { createReservationData } from '@tix/event-buyer/components';
import {
  CustomerService,
  PayoutService,
  TixHiveService
} from '@tix/event-buyer/services';
import {
  TixFeesService,
  dollarFigureToNumber,
  formatAsDollarFigure
} from '@tix/shared/utils';
import { flatten } from 'lodash';
import { map, tap } from 'rxjs/operators';
import * as BuyerActions from '../actions';
import { OrderService } from '../services';
@Injectable()
export class EventBuyerEffects {
  getVenueInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerActions.getVenueInfo),
      fetch({
        run: ({ venueId }) => {
          return this.getVenueInfo
            .fetch({ venueId: venueId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) => {
                return BuyerActions.getVenueInfoSuccess({
                  VenueInfo: data.VenueByPK as TixVenue
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return BuyerActions.getVenueInfoFailure({ error });
        }
      })
    )
  );

  insertPaymentReservationTickets = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerActions.insertPaymentReservationTicket),
      fetch({
        run: ({
          logToHive,
          paymentIntent,
          paymentReservationObj,
          venueId,
          orderData,
          reload,
          amountInfo,
          form,
          checkCustomerEmail,
          products
        }) => {
          const { tickets, groupCode, isGroup } = orderData;
          const reservationProcessingFee =
            amountInfo.totalProcessingFee - amountInfo.orderItemsProcessingFee;
          const filteredTickets = tickets.filter(
            (ticket: any) => ticket.ticketQty > 0
          );
          const updatedTicketConfigData = filteredTickets.map(
            (ticket: any) => ({
              ...ticket,
              groupCode: groupCode,
              isGroup: isGroup
            })
          );
          const ticketsWithQuantity = this.calculateAndAdjustFees(
            updatedTicketConfigData,
            reservationProcessingFee,
            orderData
          );

          const insertPaymentData: TixPaymentInsertInput = {
            ...paymentReservationObj,
            Reservation: {
              data: {
                ...createReservationData(
                  flatten(ticketsWithQuantity),
                  paymentReservationObj?.Contact
                ).data,
                reservation: {
                  data: {
                    ...createReservationData(
                      flatten(ticketsWithQuantity),
                      paymentReservationObj?.Contact
                    ).data.reservation?.data,
                    agreeToTerms: true,
                    totalAmountPaid: formatAsDollarFigure(
                      amountInfo.totalAmountPaid
                    ),
                    subTotal: formatAsDollarFigure(
                      amountInfo.totalSubtotal - amountInfo.orderItemsSubtotal
                    ),
                    processingFee: formatAsDollarFigure(
                      reservationProcessingFee
                    ),
                    discountId: amountInfo.discountId,
                    discountAmount: amountInfo.discountAmount,
                    platformFee: amountInfo.platformFee,
                    venueFee: amountInfo.venueFee
                  }
                }
              }
            }
          };
          return this.insertPaymentReservationTicket
            .mutate({ insertPayment: insertPaymentData })
            .pipe(
              tap(({ data }) => {
                const reservationTicketOfPayment =
                  data?.InsertPayment?.returning[0];
                if (logToHive) {
                  this.hiveService.logOrder({
                    eventId:
                      reservationTicketOfPayment?.Reservation?.reservation
                        ?.eventInstanceId,
                    orderId:
                      reservationTicketOfPayment?.Reservation?.reservation
                        ?.reservationId,

                    totalPaid: amountInfo.totalAmountPaid, // to review
                    venueId: venueId
                  });
                }
              }),
              map(({ data }) => {
                const reservationTicketOfPayment =
                  data?.InsertPayment?.returning[0];
                if (reservationTicketOfPayment) {
                  // start addons
                  const reservationInfo =
                    reservationTicketOfPayment.Reservation?.reservation;
                  this._orderService.orderedProducts(products, {
                    reservationId: reservationInfo?.reservationId,
                    contactId: reservationInfo?.contactId,
                    orderItemsProcessingFee: amountInfo.orderItemsProcessingFee,
                    totalTax: amountInfo.totalTax
                  });
                  // end addons

                  this.sendEmailNotificationMutation
                    .mutate({
                      data: {
                        reservationId:
                          reservationTicketOfPayment.Reservation?.reservation
                            ?.reservationId,
                        eventInstanceId:
                          reservationTicketOfPayment.Reservation?.reservation
                            ?.eventInstanceId
                      }
                    })
                    .toPromise()
                    .catch(console.error);

                  this.payoutService.createPayoutDetail({
                    reservationId:
                      reservationTicketOfPayment.Reservation?.reservation
                        ?.reservationId,
                    eventInstanceId:
                      reservationTicketOfPayment.Reservation?.reservation
                        ?.eventInstanceId
                  });

                  this.sendETicket
                    .mutate({
                      data: {
                        baseURL: window.location.origin + '/check-ticket',
                        reservationId:
                          reservationTicketOfPayment.Reservation?.reservation
                            ?.reservationId
                      }
                    })
                    .subscribe(() => {
                      const reservationId =
                        reservationTicketOfPayment.Reservation?.reservation
                          ?.reservationId;
                      this.router
                        .navigate(['/success', venueId, reservationId])
                        .then(() => {
                          // if (reload) window.location.reload();
                        });
                    });

                  if (paymentIntent) {
                    this.updatePaymentIntentMetadataMutation
                      .mutate({
                        mode: paymentIntent.livemode ? 'live' : 'test',
                        paymentIntentId: paymentIntent.id,
                        metadataJson: JSON.stringify({
                          contact_id:
                            reservationTicketOfPayment.Reservation?.reservation
                              ?.contactId,
                          event_instance_id:
                            reservationTicketOfPayment.Reservation?.reservation
                              ?.eventInstanceId,
                          reservation_id:
                            reservationTicketOfPayment.Reservation?.reservation
                              ?.reservationId
                        })
                      })
                      .toPromise()
                      .catch(console.error);
                  }
                  if (!checkCustomerEmail.length) {
                    const newCustomer = this.customerService.updateCustomer({
                      ...form,
                      contactId:
                        reservationTicketOfPayment.Reservation?.reservation
                          ?.contactId
                    });
                  }
                  return BuyerActions.insertPaymentReservationTicketSuccess({
                    paymentObj: reservationTicketOfPayment as TixPayment
                  });
                }
                return BuyerActions.insertPaymentReservationTicketFailure({
                  error: 'Records not found!'
                });
              })
            );
        },
        onError: (action, error) => {
          console.error(error);
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return BuyerActions.insertPaymentReservationTicketFailure({ error });
        }
      })
    )
  );

  calculateAndAdjustFees(
    updatedTicketConfigData: any,
    reservationProcessingFee: any,
    orderData: any
  ) {
    const ticketsProcessingFeeArray = updatedTicketConfigData.map(
      (ticket: any) => {
        const processingFeePerTicket =
          parseFloat(ticket.processingFee) * parseInt(ticket.ticketQty);
        return processingFeePerTicket;
      }
    );
    const totalProcessingFee = ticketsProcessingFeeArray.reduce(
      (a: any, c: any) => a + c,
      0
    );
    const resProcessingFee =
      reservationProcessingFee && !isNaN(reservationProcessingFee)
        ? reservationProcessingFee.toFixed(2)
        : 0;
    if (totalProcessingFee.toFixed(2) !== resProcessingFee) {
      const diff = resProcessingFee - totalProcessingFee;
      const adjustedTickets = updatedTicketConfigData.map(
        (ticket: any, ticketTypeIndex: number) => {
          const duplicatedTickets = new Array(ticket.ticketQty)
            .fill({})
            .map((_, index) => {
              let processingFee = parseFloat(ticket.processingFee);
              if (ticketTypeIndex === 0 && index === ticket.ticketQty - 1) {
                processingFee += diff;
              }
              return {
                ...ticket,
                eventInstanceId: orderData.eventInstanceId,
                discountAmount: ticket.discountAmount,
                processingFee: processingFee.toFixed(2)
              };
            });
          return duplicatedTickets;
        }
      );
      return adjustedTickets;
    } else {
      const adjustedTickets = updatedTicketConfigData.map((ticket: any) => {
        if (ticket.ticketQty > 0) {
          const duplicatedTickets = new Array(ticket.ticketQty)
            .fill({})
            .map(i => ({
              ...ticket,
              eventInstanceId: orderData.eventInstanceId,
              discountAmount: ticket.discountAmount
            }));
          return duplicatedTickets;
        } else return ticket;
      });
      return adjustedTickets;
    }
  }
  constructor(
    // products/addons
    private _orderService: OrderService,
    //
    private feesService: TixFeesService,
    private hiveService: TixHiveService,
    private actions$: Actions,
    private readonly getVenueInfo: TixGetVenueByPrimaryKeyGQL,
    private readonly insertPaymentReservationTicket: TixInsertPaymentReservationTicketGQL,
    private readonly sendETicket: TixSendETicketGQL,
    private readonly store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private sendEmailNotificationMutation: TixSendTicketPurchaseEmailGQL,
    private updatePaymentIntentMetadataMutation: TixUpdatePaymentIntentMetadataGQL,
    private decrementDiscountLimit: TixDecrementDiscountLimitGQL,

    private payoutService: PayoutService
  ) {}
}
