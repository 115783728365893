import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Roles } from '@tix/auth/state';
import {
  TixDeleteContactCompanyRolesGQL,
  TixDeleteContactPhoneNumbersGQL,
  TixGetContactsByCompanyIdGQL,
  TixGetContactsByCompanyIdQuery,
  TixGetRolesGQL,
  TixGetRolesQuery,
  TixGetStaffByPkGQL,
  TixGetStaffByPkQuery,
  TixInsertCompanyContactUserRolesGQL,
  TixInsertContactPhoneNumberGQL,
  TixInsertExistingUserToStaffGQL,
  TixInsertUserCompanyContactGQL,
  TixSendMailByEmailGQL,
  TixUpdateContactByPkGQL,
  TixUpdateContactByPkMutationVariables,
  TixDeleteUserFromStaffGQL,
  TixGetEventsByEventAdminGQL,
  TixUpdateEventAdminForEventGQL
} from '@tix/data-access';
import { BehaviorSubject, of, throwError, Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  tap,
  take,
  switchMap
} from 'rxjs/operators';

import * as UserSelectors from '@tix/auth/state/selectors';

export type TixRoleList = NonNullable<TixGetRolesQuery['Role']>;
export type TixRoleListItem = NonNullable<TixRoleList[0]>;
export type TixFullStaffMember = NonNullable<
  TixGetStaffByPkQuery['ContactByPK']
>;
export type TixStaffMember = NonNullable<
  TixGetContactsByCompanyIdQuery['CompanyContact'][0]
>;
export type TixStaffMemberList = TixStaffMember[];

const errorMessage = 'An error has ocurred';
const saveSuccessMessage = 'Your changes have been saved successfully!';

@Injectable({
  providedIn: 'root'
})
export class TixStaffService {
  // Subjects
  private rolesBS = new BehaviorSubject<TixRoleList>([]);

  private staffListBS = new BehaviorSubject(
    new Map<string, TixStaffMemberList>()
  );
  private fullStaffMembersBS = new BehaviorSubject(
    new Map<string, TixFullStaffMember>()
  );

  private isLoadingStaffListBS = new BehaviorSubject<boolean>(false);
  private isLoadingFullStaffBS = new BehaviorSubject<boolean>(false);
  private isLoadingRolesBS = new BehaviorSubject<boolean>(false);
  private isSavingStaffInfoBS = new BehaviorSubject<boolean>(false);
  private isDeletingStaffInfoBS = new BehaviorSubject<boolean>(false);
  private isSavingStaffRolesBS = new BehaviorSubject<boolean>(false);
  private isSavingStaffPhoneNumbersBS = new BehaviorSubject<boolean>(false);

  // Observables
  public roles$ = this.rolesBS.asObservable();

  public staffList$ = this.staffListBS.asObservable();
  public fullStaffMembers$ = this.fullStaffMembersBS.asObservable();

  public isLoadingStaffList$ = this.isLoadingStaffListBS.asObservable();
  public isLoadingFullStaff$ = this.isLoadingFullStaffBS.asObservable();
  public isLoadingRoles$ = this.isLoadingRolesBS.asObservable();
  public isSavingStaffInfo$ = this.isSavingStaffInfoBS.asObservable();
  public isDeletingStaffInfo$ = this.isDeletingStaffInfoBS.asObservable();
  public isSavingStaffRoles$ = this.isSavingStaffRolesBS.asObservable();
  public isSavingStaffPhoneNumbers$ =
    this.isSavingStaffPhoneNumbersBS.asObservable();

  constructor(
    private snackbar: MatSnackBar,
    private store: Store,
    private getRolesQuery: TixGetRolesGQL,
    private getStaffListQuery: TixGetContactsByCompanyIdGQL,
    private getStaffMemberByIdQuery: TixGetStaffByPkGQL,
    private updateContactByIdMutation: TixUpdateContactByPkGQL,
    private insertNewCompanyContactMutation: TixInsertUserCompanyContactGQL,
    private insertExistingContactToStaffMutation: TixInsertExistingUserToStaffGQL,
    private insertUserCompanyContactUserRolesMutation: TixInsertCompanyContactUserRolesGQL,
    private deleteUserCompanyRolesMutation: TixDeleteContactCompanyRolesGQL,
    private insertContactPhoneNumbersMutation: TixInsertContactPhoneNumberGQL,
    private deleteContactPhoneNumbersMutation: TixDeleteContactPhoneNumbersGQL,
    private sendInviteByMailMutation: TixSendMailByEmailGQL,
    private deleteUserFromStaffMutation: TixDeleteUserFromStaffGQL,
    private getEventsByEventAdmin: TixGetEventsByEventAdminGQL,
    private updateEventEventAdmin: TixUpdateEventAdminForEventGQL
  ) {}

  private userId$ = this.store
    .select(UserSelectors.getAuthenticatedUser)
    .pipe(map(u => u?.uid));

  private clearCache() {
    this.staffListBS.next(new Map());
    this.fullStaffMembersBS.next(new Map());
  }

  public getRoles() {
    return this.roles$.pipe(
      mergeMap(roles => {
        if (roles.length) return of(roles);

        this.isLoadingRolesBS.next(true);
        return this.getRolesQuery.fetch().pipe(
          map(res => res.data.Role),
          tap(data => {
            this.rolesBS.next(data);
            this.isLoadingRolesBS.next(false);
          })
        );
      })
    );
  }

  public getStaffListForCompany(companyId: string) {
    return this.staffList$.pipe(
      take(1),

      mergeMap(staffList => {
        const cached = staffList.get(companyId);
        if (cached) return of(cached);

        this.isLoadingStaffListBS.next(true);
        return this.getStaffListQuery
          .fetch(
            {
              companyId
            },
            { fetchPolicy: 'no-cache' }
          )
          .pipe(
            map(res => res.data.CompanyContact as TixStaffMemberList),
            tap(data => {
              this.isLoadingStaffListBS.next(false);
              const cache = this.staffListBS.value;
              cache.set(companyId, data);
              this.staffListBS.next(cache);
            })
          );
      })
    );
  }

  public getStaffMemberById(id: string) {
    return this.fullStaffMembers$.pipe(
      take(1),
      mergeMap(cache => {
        const cached = cache.get(id);
        if (cached) return of(cached);

        this.isLoadingFullStaffBS.next(true);
        return this.getStaffMemberByIdQuery
          .fetch(
            {
              contactId: id
            },
            { fetchPolicy: 'no-cache' }
          )
          .pipe(
            map(res => res.data.ContactByPK as TixFullStaffMember),
            tap(data => {
              this.isLoadingFullStaffBS.next(false);
              const cache = this.fullStaffMembersBS.value;
              cache.set(id, data);
              this.fullStaffMembersBS.next(cache);
            })
          );
      })
    );
  }

  public saveStaffMemberInfo(data: TixUpdateContactByPkMutationVariables) {
    this.isSavingStaffInfoBS.next(true);
    return this.userId$.pipe(
      mergeMap(uid =>
        this.updateContactByIdMutation
          .mutate({ ...data, updatedAt: 'now()', updatedBy: uid })
          .pipe(
            mergeMap(() => {
              this.isSavingStaffInfoBS.next(false);
              this.clearCache();
              this.snackbar.open(saveSuccessMessage);
              return this.getStaffMemberById(data.contactId);
            }),
            catchError(error => {
              this.isSavingStaffInfoBS.next(false);
              this.snackbar.open(errorMessage);
              return throwError(error);
            })
          )
      )
    );
  }

  public insertExistingUserAsStaffMember(
    companyId: string,
    contactId: string,
    roleIds: string[]
  ) {
    this.isSavingStaffInfoBS.next(true);
    return this.insertExistingContactToStaffMutation
      .mutate({
        companyId,
        contactId
      })
      .pipe(
        mergeMap(() => {
          this.isSavingStaffInfoBS.next(false);
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);
          return this.getStaffMemberById(contactId);
        }),
        mergeMap(() => {
          return this.saveStaffMemberRoles(companyId, contactId, roleIds);
        }),
        catchError(error => {
          this.isSavingStaffInfoBS.next(false);
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }
  public removeExistingUserAsStaff(
    companyId: string,
    contactId: string,
    userId: string,
    isEventAdmin: boolean
  ) {
    this.saveStaffMemberRoles(companyId, contactId, []);
    this.isDeletingStaffInfoBS.next(true);
    return this.deleteUserFromStaffMutation
      .mutate({ companyId, contactId })
      .pipe(
        mergeMap(async () => {
          this.isDeletingStaffInfoBS.next(false);
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);
          if (isEventAdmin)
            await this.removeEventAdminFromRelatedEvents(userId);

          return this.getStaffListForCompany(companyId);
        }),
        catchError(error => {
          this.isDeletingStaffInfoBS.next(false);
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  async removeEventAdminFromRelatedEvents(adminId: string) {
    const events = await this.getEventsByEventAdmin
      .fetch(
        {
          adminId: [adminId]
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(map(res => res.data.EventInstance))
      .toPromise();

    if (events) {
      const promises = events.map(
        event =>
          new Promise(resolve => {
            let eventAdmins = [...(event.eventAdmins ?? [])];
            const index = eventAdmins.findIndex(admin => admin === adminId);
            if (index > -1) {
              eventAdmins.splice(index, 1);
              this.updateEventEventAdmin
                .mutate({
                  eventInstanceId: event.eventInstanceId,
                  eventAdmins
                })
                .pipe()
                .toPromise()
                .then(resolve);
            }
          })
      );
      await Promise.all(promises);
    }
  }

  public insertNewUserToCompanyStaff(
    companyId: string,
    data: {
      contactEmailAddress: string;
      contactFirstName: string;
      contactLastName: string;
      roles: string[];
    }
  ) {
    const vars = {
      emailAddress: data.contactEmailAddress,
      companyId,
      firstName: data.contactFirstName,
      lastName: data.contactLastName,
      data: data.roles.map(roleId => ({ companyId, roleId }))
    };

    return this.userId$.pipe(
      switchMap(uid =>
        this.insertNewCompanyContactMutation
          .mutate({ ...vars, createdBy: uid, createdAt: 'now()' })
          .pipe(
            mergeMap(res => {
              this.isSavingStaffInfoBS.next(false);
              this.isSavingStaffRolesBS.next(false);
              this.clearCache();
              this.snackbar.open(saveSuccessMessage);
              return this.sendInviteByMailMutation
                .mutate({
                  email: data.contactEmailAddress
                })
                .pipe(
                  catchError(error => {
                    console.error(error);

                    return this.getStaffMemberById(
                      res.data?.InsertUserOne?.contactId
                    );
                  }),
                  mergeMap(() => {
                    return this.getStaffMemberById(
                      res.data?.InsertUserOne?.contactId
                    );
                  })
                );
            }),
            catchError(error => {
              this.isSavingStaffInfoBS.next(false);
              this.isSavingStaffRolesBS.next(false);
              this.snackbar.open(errorMessage);
              return throwError(error);
            })
          )
      )
    );
  }

  public saveStaffMemberRoles(
    companyId: string,
    contactId: string,
    roleIds: string[]
  ) {
    this.isSavingStaffRolesBS.next(true);

    return this.getStaffMemberById(contactId).pipe(
      mergeMap(contact => {
        return this.deleteUserCompanyRolesMutation.mutate({
          companyId,
          userId: contact.user?.userId
        });
      }),
      mergeMap(() => {
        return this.getStaffMemberById(contactId);
      }),
      mergeMap(contact =>
        this.userId$.pipe(
          mergeMap(uid =>
            this.insertUserCompanyContactUserRolesMutation.mutate({
              objects: roleIds.map(roleId => ({
                companyId,
                userId: contact.user?.userId,
                roleId,
                updatedAt: 'now()',
                updatedBy: uid
              }))
            })
          )
        )
      ),
      tap(() => {
        this.clearCache();
        this.isSavingStaffRolesBS.next(false);
        this.snackbar.open(saveSuccessMessage);
      }),
      catchError((error, ...args) => {
        this.snackbar.open(errorMessage);
        return throwError(error);
      })
    );
  }

  public saveStaffMemberPhoneNumbers(
    contactId: string,
    data: { countryCode: string; type: string; phoneNumber: string }[]
  ) {
    this.isSavingStaffPhoneNumbersBS.next(true);
    return this.deleteContactPhoneNumbersMutation
      .mutate({
        contactId
      })
      .pipe(
        mergeMap(() => {
          return this.userId$.pipe(
            mergeMap(uid =>
              this.insertContactPhoneNumbersMutation.mutate({
                objects: data.map(p => ({
                  ...p,
                  updatedAt: 'now()',
                  updatedBy: uid,
                  contactPhoneNumbers: {
                    data: [
                      {
                        contactId
                      }
                    ]
                  }
                }))
              })
            )
          );
        }),
        mergeMap(() => {
          this.isSavingStaffPhoneNumbersBS.next(false);
          this.snackbar.open(saveSuccessMessage);
          this.clearCache();

          return this.getStaffMemberById(contactId);
        }),
        catchError((error, ...args) => {
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  public getCurrentUserRolesInCompany(companyId: string): Observable<Roles[]> {
    return this.store.select(UserSelectors.getUserRolesInfo).pipe(
      map(user => {
        const userRolesInCompany: Roles[] =
          user?.userRoles
            .filter(role => role.companyId === companyId)
            .map(role => role.role?.role as Roles) || [];
        return userRolesInCompany;
      })
    );
  }
}
