import { Injectable } from '@angular/core';
import { TixFeesService } from '@tix/shared/utils';
import {
  DiscountOrderItem,
  DiscountType,
  Order,
  ProductOrderItem,
  TicketOrderItem
} from './types';

interface IOrderCalculator {
  calculateSubtotal(order: Order): Promise<number>;
  calculateProductSubtotal(order: Order): number;
  calculateProcessingFee(order: Order): Promise<number>;
  calculatePlatformFee(order: Order): Promise<number>;
  calculateVenueFee(order: Order): Promise<number>;
  calculateProductTaxes(order: Order): Promise<number>; // no need for promise!
  calculateDiscountPerTicket(
    order: Order,
    ticketConfigId: string,
    discountAmount: number,
    discountType: DiscountType,
    discountAppliedTo: string | null | undefined
  ): Promise<number | null>;
  calculateProcessingFeePerTicket(
    order: Order,
    ticketConfigId: string
  ): Promise<number>;
  calculateAmountToPay(order: Order): Promise<number>;
}

@Injectable({
  providedIn: 'root'
})
export class OrderCalculatorService implements IOrderCalculator {
  private venueId: string;
  private companyId: string;

  constructor(private feesService: TixFeesService) {}

  setVenueId(venueId: string) {
    this.venueId = venueId;
  }

  setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  private verifyInformationProvided() {
    if (!this.venueId || !this.companyId) {
      throw new Error(
        'You must set the venueId and companyId before calculating'
      );
    }
  }

  async calculateDiscountPerTicket(
    order: Order,
    ticketConfigId: string,
    discountAmount: number,
    discountType: DiscountType,
    discountAppliedTo: string | null | undefined
  ): Promise<number> {
    const ticketPrice =
      order.items.find(
        ticket =>
          ticket instanceof TicketOrderItem &&
          ticket.ticketConfigId == ticketConfigId
      )?.amount ?? 0;
    if (discountAppliedTo === 'Ticket') {
      if (discountType === '$ Discount') {
        return discountAmount;
      }
      if (discountType === '% Discount') {
        return (discountAmount / 100) * ticketPrice;
      }
      return 0;
    } else {
      if (discountType === '% Discount') {
        return (discountAmount / 100) * ticketPrice;
      }
    }
    return 0;
  }

  async calculateSubtotal(order: Order): Promise<number> {
    return order.items.reduce((total, item) => {
      if (item instanceof TicketOrderItem || item instanceof ProductOrderItem) {
        return total + item.amount * item.quantity;
      }

      return total;
    }, 0);
  }

  calculateProductSubtotal(order: Order) {
    return order.items.reduce((total, item) => {
      if (item instanceof ProductOrderItem) {
        return total + item.amount * item.quantity;
      }

      return total;
    }, 0);
  }

  async calculateProcessingFee(order: Order): Promise<number> {
    this.verifyInformationProvided();

    const discount = order.items.find(
      item => item instanceof DiscountOrderItem
    );
    const discountAmount = discount ? discount.amount : 0;

    const includeFees = order.items.some(
      item => item instanceof TicketOrderItem && item.includesFees
    );

    const subtotal = await this.calculateSubtotal(order);
    if (discountAmount >= subtotal) {
      return 0;
    }

    let totalProcessingFee = 0;

    if (includeFees) {
      totalProcessingFee =
        await this.feesService.calculateProcessingFeeForAmount(
          subtotal - discountAmount,
          {
            venueId: this.venueId,
            companyId: this.companyId,
            disableEnhancementFee: true
          }
        );
    } else {
      const platformFee = await this.calculatePlatformFee(order);
      const totalVenueFee = await this.calculateVenueFee(order);
      const taxes = await this.calculateProductTaxes(order);
      totalProcessingFee =
        await this.feesService.calculateProcessingFeeForAmount(
          subtotal - discountAmount + totalVenueFee + platformFee + taxes,
          {
            venueId: this.venueId,
            companyId: this.companyId
          }
        );
    }
    return totalProcessingFee;
  }

  async calculateProcessingFeePerTicket(
    order: Order,
    ticketConfigId: string
  ): Promise<number> {
    const totalProcessingFee = await this.calculateProcessingFee(order);
    const subtotal = await this.calculateSubtotal(order);

    const ticketAmount =
      order.items.find(
        ticket =>
          ticket instanceof TicketOrderItem &&
          ticket.ticketConfigId == ticketConfigId
      )?.amount ?? 0;

    const percentage = ticketAmount / subtotal;

    return totalProcessingFee * percentage;
  }

  async calculatePlatformFee(order: Order): Promise<number> {
    this.verifyInformationProvided();

    const discount = order.items.find(
      item => item instanceof DiscountOrderItem
    );
    const discountAmount = discount ? discount.amount : 0;
    const subtotal = await this.calculateSubtotal(order);

    if (discountAmount >= subtotal) {
      return 0;
    }

    const promises = order.items.map(async item => {
      if (item instanceof TicketOrderItem) {
        return this.feesService.getPlatformFee(item.amount, {
          venueId: this.venueId,
          companyId: this.companyId,
          venueFee: item.includesFees ? 0 : item.venueFee,
          quantity: item.quantity
        });
      } else {
        return 0;
      }
    });

    const fees = await Promise.all(promises);
    return fees.reduce((total, fee) => total + fee, 0);
  }

  async calculateVenueFee(order: Order): Promise<number> {
    const discount = order.items.find(
      item => item instanceof DiscountOrderItem
    );
    const discountAmount = discount ? discount.amount : 0;
    const subtotal = await this.calculateSubtotal(order);

    if (discountAmount >= subtotal) {
      return 0;
    }

    return order.items.reduce((total, item) => {
      if (item instanceof TicketOrderItem && !item.includesFees) {
        return total + item.venueFee * item.quantity;
      }

      return total;
    }, 0);
  }

  async calculateProductTaxes(order: Order): Promise<number> {
    return order.items.reduce((total, item) => {
      if (item instanceof ProductOrderItem) {
        return total + (item.taxPercent / 100) * item.amount * item.quantity;
      }
      return total;
    }, 0);
  }

  async calculateAmountToPay(order: Order): Promise<number> {
    const includeFees = order.items.some(
      item => item instanceof TicketOrderItem && item.includesFees
    );

    const discount = order.items.find(
      item => item instanceof DiscountOrderItem
    );
    const discountAmount = discount ? discount.amount : 0;

    const subtotal = await this.calculateSubtotal(order);

    if (discountAmount >= subtotal) return 0;

    if (includeFees) {
      return subtotal - discountAmount;
    } else {
      const platformFee = await this.calculatePlatformFee(order);
      const totalVenueFee = await this.calculateVenueFee(order);
      const processingFee = await this.calculateProcessingFee(order);
      const taxes = await this.calculateProductTaxes(order);
      return (
        subtotal -
        discountAmount +
        totalVenueFee +
        platformFee +
        processingFee +
        taxes
      );
    }
  }
}
